/*Package Imports */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import classNames from "classnames";
import { Badge, Collapse, Row } from "reactstrap";
import Cloud from "react-ionicons/lib/IosCloud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog, faCommentDollar, faEye, faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
/*App Imports*/
import routes from "../routes/index";

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, { [index]: isActive || isOpen || isHome });
  });

  return _routes;
};

const SidebarCategory = withRouter(({ name, badgeColor, badgeText, icon, isOpen, children, onClick, location, to }) => {
  const getSidebarItemClass = (path) => {
    return location.pathname.indexOf(path) !== -1 || (location.pathname === "/" && path === "/dashboard") ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <span data-toggle="collapse" className={"sidebar-link " + (!isOpen ? "collapsed" : "")} onClick={onClick} aria-expanded={isOpen ? "true" : "false"}>
        <FontAwesomeIcon className="align-middle mr-2" icon={icon} fixedWidth />
        <span className="align-middle">{name}</span>
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </span>
      <Collapse isOpen={isOpen}>
        <ul id="item" className={"sidebar-dropdown list-unstyled"}>
          {children}
        </ul>
      </Collapse>
    </li>
  );
});

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon, location, to }) => {
  const currentColorMode = useSelector((state) => state.theme.currentColorMode);

  const getSidebarItemClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <li className={"sidebar-item " + getSidebarItemClass(to)}>
      <NavLink to={to} className="sidebar-link" activeClassName="active">
        {icon ? (
          <React.Fragment>
            <FontAwesomeIcon className="align-middle mr-2" icon={icon} fixedWidth style={{ color: currentColorMode.color }} />
            <span className="align-middle" style={{ color: currentColorMode.color }}>
              {name}
            </span>
          </React.Fragment>
        ) : (
          name
        )}
        {badgeColor && badgeText ? (
          <Badge color={badgeColor} size={18} pill className="sidebar-badge">
            {badgeText}
          </Badge>
        ) : null}
      </NavLink>
    </li>
  );
});

const Sidebar = ({ location }) => {
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  const isOnRight = useSelector((state) => state.sidebar.isOnRight);
  const currentColorMode = useSelector((state) => state.theme.currentColorMode);
  const currentMember = useSelector((state) => state.member);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
  const [redirectHome, setRedirectHome] = useState(false);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach((item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false })));

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  };

  const redirect = () => {
    setRedirectHome(true);
  };

  return (
    <React.Fragment>
      {isAuth ? "" : <Redirect to="/auth/404" />}
      {redirectHome ? <Redirect to="/dashboard" /> : ""}
      <nav className={classNames("sidebar", isOpen || "toggled", !isOnRight || "sidebar-right")}>
        <div className="sidebar-content">
          <a className={classNames("sidebar-brand", !isOnRight || "text-right")} onClick={redirect}>
            <div className="text-center">
              {isOnRight || <Cloud style={{ cursor: "pointer" }} />}{" "}
              <span className="align-middle white-font" style={{ cursor: "pointer" }}>
                MIMO Back Office
              </span>{" "}
              {!isOnRight || <Cloud style={{ cursor: "pointer" }} />}
            </div>
          </a>
          <div className="sidebar-user" style={{ backgroundColor: currentColorMode.backgroundColor }}>
            <Row className="justify-content-center mb-1">
              <div className="avatar">
                <div className="avatar-title rounded-circle bg-primary-dark">
                  <FontAwesomeIcon
                    className="align-middle"
                    fixedWidth
                    icon={currentMember.role === "Admin" ? faUserCog : currentMember.role === "Viewer" ? faEye : currentMember.role === "Service" || currentMember.role === "AdminS" ? faCommentDollar : currentMember.role === "Billing" ? faHandHoldingUsd : ""}
                  />
                </div>
              </div>
            </Row>
            <div className="font-weight-bold" style={{ color: currentColorMode.color }}>
              {currentMember.firstName} {currentMember.lastName}
            </div>
            <small style={{ color: currentColorMode.color }}>{currentMember.role}</small>
          </div>
          <ul className="sidebar-nav" style={{ backgroundColor: currentColorMode.backgroundColor }}>
            {routes
              .filter((route) => {
                if (currentMember.role === "Service") {
                  return route.name !== "Errors & Alerts";
                } else if (currentMember.role === "Viewer") {
                  return route.name !== "Members";
                } else if (currentMember.role === "Billing") {
                  return route.name === "Dashboard" || route.name === "Settings";
                } else {
                  return route;
                }
              })
              .map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.children ? (
                      <SidebarCategory name={category.name} badgeColor={category.badgeColor} badgeText={category.badgeText} icon={category.icon} to={category.path} isOpen={openRoutes[index]} onClick={() => toggle(index)}>
                        {category.children.map((route, index) => (
                          <SidebarItem key={index} name={route.name} to={route.path} badgeColor={route.badgeColor} badgeText={route.badgeText} />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem name={category.name} to={category.path} icon={category.icon} badgeColor={category.badgeColor} badgeText={category.badgeText} />
                    )}
                  </React.Fragment>
                );
              })}
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
