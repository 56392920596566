import React from "react";
import classNames from "classnames";
import bgPattern from "../assets/img/photos/bg-pattern.png";

const Wrapper = ({ className, children }) => (
  <div className={classNames("wrapper", className)} style={{ backgroundImage: bgPattern }}>
    {children}
  </div>
);

export default Wrapper;
