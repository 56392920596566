/*Package Imports*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Form, FormGroup, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";

const ResetPassword = (props) => {
  const enteredEmail = useSelector((state) => state.auth.email);
  const [resetEmail, setResetEmail] = useState("");

  /*Need to implement send the reset password link email to member*/
  // const changePassword = () => {
  //   if (resetPassword) {
  //     Toastr({
  //       position: "top-center",
  //       type: "success",
  //       message: `Please check your email for your new information. This may take a few minutes.`,
  //     });
  //   }
  //   setResetPassword(!resetPassword);
  // };

  useEffect(() => {
    if (enteredEmail === undefined && localStorage.getItem("rememberMe") === "true") {
      setResetEmail(localStorage.getItem("user"));
    } else {
      setResetEmail(enteredEmail);
    }
  }, [enteredEmail]);

  return (
    <React.Fragment>
      <div className="text-center mt-4 white-font">
        <h1 className="white-font">Forgot your password?</h1>
        <p className="lead">Enter your email to receive a link to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input bsSize="lg" type="email" name="email" placeholder="Enter your email" value={resetEmail} />
              </FormGroup>
              <div className="text-center mt-3">
                <Link to="/">
                  <Button className="mr-2" color="primary" size="lg" onClick={props.onClick}>
                    Reset password
                  </Button>
                </Link>
                <Link to="/">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={() => {
                      window.location.pathname = "/";
                    }}
                  >
                    Return to Sign In
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
