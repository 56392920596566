import async from "../components/Async";

//icons for nav tabs
import {
  faHome,
  faSignInAlt,
  faExclamationCircle, //alerts page icon
  faChartLine, //reports page icon
  faAddressCard, //members page icon
  faCog, //settings page icon
  faDesktop, //mimo core page icon
} from "@fortawesome/free-solid-svg-icons";

// Auth
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import SignInPage from "../pages/backoffice/Sign In Page/SignInPage";
import SessionClosed from "../pages/auth/SessionClosed";

// Dashboards
const Default = async(() => import("../pages/backoffice/dashboards/Default"));

// Backoffice
const AlertsPage = async(() => import("../pages/backoffice/Alerts Page/AlertsPage"));
const ReportsPage = async(() => import("../pages/backoffice/Reports Page/ReportsPage"));
const MembersPage = async(() => import("../pages/backoffice/Members Page/MembersPage"));
const SettingsPage = async(() => import("../pages/backoffice/Settings Page/SettingsPage"));
const MIMOCorePage = async(() => import("../pages/backoffice/MIMOCorePage/MIMOCorePage"));

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "Main",
  icon: faHome,
  component: Default,
  children: null,
};

//Backoffice Pages
const membersPageRoute = {
  path: "/members",
  name: "Members",
  icon: faAddressCard,
  component: MembersPage,
  children: null,
};

const alertsPageRoute = {
  path: "/errorsalerts",
  name: "Errors & Alerts",
  icon: faExclamationCircle,
  component: AlertsPage,
  children: null,
};

const reportsPageRoute = {
  path: "/reports",
  name: "Reports",
  icon: faChartLine,
  component: ReportsPage,
  children: null,
};

const mimoCorePageRoute = {
  path: "/mimocore",
  name: "MIMO Core",
  icon: faDesktop,
  component: MIMOCorePage,
  children: null,
};

const settingsPageRoute = {
  path: "/settings",
  name: "Settings",
  icon: faCog,
  component: SettingsPage,
  children: null,
};

//END Backoffice Pages

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignInPage,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/SessionClosed",
      name: "Session Closed",
      component: SessionClosed,
    },
  ],
};

// Dashboard specific routes
export const adminDashboard = [dashboardRoutes, alertsPageRoute, reportsPageRoute, membersPageRoute, mimoCorePageRoute, settingsPageRoute];

export const viewerDashboard = [dashboardRoutes, alertsPageRoute, reportsPageRoute, settingsPageRoute];

export const billingDashboard = [dashboardRoutes, settingsPageRoute];

// Auth specific routes
export const page = [authRoutes];

// All routes, controls what is shown in the nav bar
export default [
  dashboardRoutes,
  alertsPageRoute,
  reportsPageRoute,
  membersPageRoute,
  // mimoCorePageRoute,
  settingsPageRoute,
];
